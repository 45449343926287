import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MetadataItem,
  ResponseCountries,
  ResponseMetadata,
  ResponseSubscriptionProducts
} from './requests';

export type Country = {
  code: string;
  name: string;
  calling_codes: string;
  latitude: string;
  longitude: string;
  timezones: string;
  native_name: string;
  flag: string;
};

export type SubscriptionProduct = {
  product_ids: string[];
  displayName: string;
  description: string;
  interval: 'monthly' | 'yearly';
  price: string;
  currency: string;
  plan: string;
  license: string;
  details: string[];
};

export default createSlice({
  name: 'metadata',
  initialState: {
    loading: false,
    error: '' as string,

    privateCode: '',

    countriesList: [] as Country[],
    industry: [] as MetadataItem[],
    companyType: [] as MetadataItem[],
    useCase: [] as MetadataItem[],
    employeesNum: [] as MetadataItem[],
    jobDescription: [] as MetadataItem[],
    knowAbout: [] as MetadataItem[],
    country: [] as MetadataItem[],

    allProducts: [] as SubscriptionProduct[],

    monthlyProducts: [] as SubscriptionProduct[],
    yearlyProducts: [] as SubscriptionProduct[]
  },
  reducers: {
    metadata: (state) => {
      state.loading = true;
    },
    onMetadataSuccess: (state, action: PayloadAction<ResponseMetadata>) => {
      state.loading = false;
      state.industry = action.payload.metadata.industry;
      state.companyType = action.payload.metadata.companyType;
      state.useCase = action.payload.metadata.useCase;
      state.employeesNum = action.payload.metadata.employeesNum;
      state.jobDescription = action.payload.metadata.job;
      state.knowAbout = action.payload.metadata.knowAbout;
    },
    onMetadataError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    countries: (state) => {
      state.loading = true;
    },

    onCountriesSuccess: (state, action: PayloadAction<ResponseCountries>) => {
      state.loading = false;
      state.countriesList = action.payload.countries;
    },

    onCountriesError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    subscriptionProducts: (state, action: PayloadAction<string | undefined>) => {
      state.loading = true;
    },
    onSubscriptionProductsSuccess: (state, action: PayloadAction<ResponseSubscriptionProducts>) => {
      state.loading = false;

      state.monthlyProducts = mapToSubscriptionProducts(
        filterSubscriptionProducts(action.payload, 'monthly')
      );
      state.yearlyProducts = mapToSubscriptionProducts(
        filterSubscriptionProducts(action.payload, 'yearly')
      );

      state.allProducts = [...state.monthlyProducts, ...state.yearlyProducts];
    },
    onSubscriptionProductsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    }
  }
});

export function mapToSubscriptionProducts(
  payload: ResponseSubscriptionProducts
): SubscriptionProduct[] {
  return payload
    .map((product): SubscriptionProduct => {
      const plan = getProductMetadataValue(product.metadata, 'plan');
      const displayName = getProductMetadataValue(product.metadata, 'display_name');
      const license = getProductMetadataValue(product.metadata, 'license');
      const interval = getProductMetadataValue(product.metadata, 'interval');
      const price = product.prices
        .filter((item) => item.usage_type === 'licensed')
        .map((item) => item.amount_decimal);

      return {
        product_ids: [product.product_id],
        displayName,
        description: product.name,
        interval: interval as any,
        price: price.length > 0 ? price[0] : '0.00',
        plan,
        license,
        currency: product.prices.length > 0 ? product.prices[0].currency : 'usd',
        details: product.description ? [...product.description] : []
      };
    })
    .reduce((prev, current, _, array) => {
      const _currentPlan = prev.filter((product) => product.plan === current.plan);
      const currentPlan = _currentPlan ? _currentPlan[0] : null;
      const price = current.price ? parseFloat(current.price) : 0;
      const result: SubscriptionProduct = {
        product_ids: current.product_ids,
        displayName: current.displayName,
        description: current.description,
        interval: current.interval,
        price: currentPlan ? (parseFloat(currentPlan.price) + price).toString() : price.toString(),
        plan: current.plan,
        license: current.license,
        currency: current.currency === 'eur' ? '€' : '$',
        details: currentPlan ? currentPlan.details.concat(current.details) : current.details
      };
      if (!currentPlan) {
        return prev.concat(result);
      } else {
        return prev.map((item) => {
          if (item.plan === current.plan) {
            return result;
          }
          return item;
        });
      }
    }, [] as SubscriptionProduct[])
    .map((product) => {
      return {
        ...product,
        product_ids: product.product_ids.sort()
      };
    })
    .sort((a, b) => {
      const amountA = a.price === undefined ? 0 : parseFloat(a.price);
      const amountB = b.price === undefined ? 0 : parseFloat(b.price);
      return amountA - amountB;
    });
}
export function filterSubscriptionProducts(
  payload: ResponseSubscriptionProducts,
  interval: 'monthly' | 'yearly'
): ResponseSubscriptionProducts {
  return payload.filter(
    (product) => getProductMetadataValue(product.metadata, 'interval') === interval
  );
}

export function getProductMetadataValue(
  metadata: { name: string; value: string }[],
  key: string
): string {
  const value = metadata

    .filter((metadata) => metadata.name === key)
    .map((metadata) => metadata.value);
  if (value.length > 0) return value[0];
  return '';
}
