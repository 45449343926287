import { Bloock, Network } from '@bloock/sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

const apiHost = process.env.REACT_APP_API_HOST!;
if (apiHost.includes('api.bloock.dev')) {
  Bloock.setApiHost(process.env.REACT_APP_API_HOST!);

  Bloock.setProvider(Network.BLOOCK_CHAIN, 'https://ganache.bloock.dev');
  Bloock.setContractAddress(Network.BLOOCK_CHAIN, 'd2d1BBcbee7741f8C846826F55b7c17fc5cf969a');

  Bloock.setProvider(Network.ETHEREUM_GOERLI, 'https://goerli.bloock.dev');
  Bloock.setContractAddress(Network.ETHEREUM_GOERLI, 'C8a9f5D79f94cbE4DA1171783F03976594b60c95');
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS!
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
