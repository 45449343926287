import { HttpClient } from '../../http/client';

export type ResponseMetadata = {
  metadata: {
    industry: MetadataItem[];
    companyType: MetadataItem[];
    useCase: MetadataItem[];
    employeesNum: MetadataItem[];
    job: MetadataItem[];
    knowAbout: MetadataItem[];
    country: MetadataItem[];
  };
};

export type MetadataItem = {
  id: number;
  key: string;
};

export type ResponseCountries = {
  countries: {
    code: string;
    name: string;
    calling_codes: string;
    latitude: string;
    longitude: string;
    timezones: string;
    native_name: string;
    flag: string;
  }[];
};

export type ResponseSubscriptionProducts = {
  product_id: string;
  name: string;
  description: string[];
  prices: {
    price_id: string;
    currency: string;
    amount_decimal: string;
    nickname: string;
    usage_type: string;
    interval: string;
    active: boolean;
    included_amount: string;
  }[];
  metadata: { name: string; value: string }[];
}[];

export async function requestMetadata() {
  return await HttpClient.get('/users/v1/metadata');
}

export async function requestGetCountries() {
  return await HttpClient.get('/users/v1/countries');
}

export async function requestSubscriptionProducts(
  privateCode: string | undefined,
  license: string | undefined
): Promise<ResponseSubscriptionProducts> {
  let url = `/subscriptions/v1/products`;
  if (license) {
    url += `?license=${license}`;
  } else if (privateCode && !license) {
    url += `?private=${privateCode}`;
  } else if (privateCode && license) {
    url += `&private=${privateCode}`;
  }
  return await HttpClient.get(url);
}
